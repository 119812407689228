<template>
  <b-overlay :show="loading" rounded>
    <header v-if="myGudang">
      <h5>
        <strong>Lokasi Gudang: {{ this.myGudang.nama_gudang }}</strong>
      </h5>
    </header>
    <b-row>
      <b-col md="3" sm="12">
        <p><strong>Tanggal</strong></p>
        <p class="mb-2">{{ so.tanggal }}</p>
        <section class="mt-1" v-if="so.gm">
          <p><strong>General Manager</strong></p>
          <p>
            {{ so.gm.nama_lengkap }}
          </p>
        </section>
      </b-col>
      <!-- <b-col md="3" sm="12">
            <p><strong>No</strong></p>
            <p>{{ so.no }}</p>
          </b-col> -->
          <b-col md="3" sm="12">
            <p><strong>Keterangan</strong></p>
            <p>{{ so.keterangan ? so.keterangan : "-" }}</p>
          </b-col>
          <b-col md="3" sm="12" v-if="so.status == 0">
            <p><strong>Status</strong></p>
            <b-badge variant="light-danger" size="sm"
              >Belum Selesai</b-badge
            >
          </b-col>
          <b-col md="3" sm="12" v-if="so.status == 1">
            <p><strong>Status</strong></p>
            <b-badge variant="light-success" size="sm"
              >Selesai</b-badge
            >
          </b-col>
      <!-- Info Barang -->
      <!-- / -->
      </b-row>

      <section v-if="isGM && !so.id_gm" class="d-flex justify-content-end my-2">
        <b-button variant="primary" size="sm" @click.prevent="approve">Approve Stok Opname</b-button>
      </section>

      <b-row>
      <!-- Input Stock Opname -->
      <b-col cols="12">
        <b-card style="overflow-y: auto; max-height: 500px">
          <b-table small bordered striped hover responsive :fields="fields" :items="items" class="mb-2">
            <template #cell(checkbox)="{item, index}">
              <b-form-checkbox @change="updatePenyesuaian(index)" v-model="item.checked"></b-form-checkbox>
            </template>
            <template #cell(no)="row">
              <div class="text-center">
                {{ row.index + 1 }}
              </div>
            </template>
            <template #cell(so)="{ item }">
              {{ item.stok_opname_id }}
            </template>
            <template #cell(selisih)="{ item }">
              {{ item.jumlah ? item.jumlah - item.stok_real : 0 }}
            </template>
            <template #cell(stok_real)="{item}">
              <b-form-input v-model="item.stok_real" type="number"></b-form-input>
            </template>
            <template #cell(penyesuaian)="{item}">
              <b-form-input v-if="item.checked" v-model="item.penyesuaian"></b-form-input>
              <span v-else>{{ item.penyesuaian }}</span>
            </template>
          </b-table>
        </b-card>
        <section class="my-2 d-flex justify-content-end" v-if="alreadyApproved">
          <b-button variant="primary" @click.prevent="submit">Simpan Penyesuaian</b-button>
        </section>
      </b-col>
      <!-- / -->
      <!-- {{ this.items }} -->
    </b-row>
    <footer class="d-flex justify-content-end">
      <b-button v-if="so.status == 0" variant="primary" @click.prevent="save">Simpan</b-button>
    </footer>
  </b-overlay>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BForm,
  BCard,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BTable,
  BFormInput,
  BLink,
    BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BForm,
    BCard,
    BFormGroup,
    BFormSelect,
    BOverlay,
    vSelect,
    BTable,
    BFormInput,
    BLink,
    BBadge,
  },
  data() {
    return {
      loading: false,
      selectedBarang: null,
      barangs: [],
      penyimpanan_id: [],
      stok_real: 0,
      stok: 0,
      stok_opname_id: 0,
      status: 1,
      so: {
        tanggal: null,
        no: null,
        keterangan: null,
        status: null,
      },
      userData: JSON.parse(localStorage.getItem("userData")),
      fields: null,
      fieldsCabang: [
        {
          key: "no",
          label: "No",
        },
        {
          key: "barang.nama",
          label: "Nama Barang",
          sortable: true
        },
        {
          key: "blok.blok",
          label: "Blok",
          sortable: true
        },
        {
          key: "palet.palet",
          label: "palet",
        },
        {
          key: "rak.rak",
          label: "rak",
        },
        {
          key: "laci.laci",
          label: "laci",
        },
        {
          key: "jumlah",
          label: "Stok",
        },
        {
          key: "barang.satuan.satuan",
          label: "satuan",
        },
        {
          key: "stok_real",
          label: "Hasil Stok Opname",
        },
        {
          key: "selisih",
          label: "selisih barang",
        }
      ],
      items: []
    };
  },
  async mounted() {
    this.getData()
    await this.getDataSo()
    this.setField()
    if (this.$route.params.id) {
      this.stok_opname_id = this.$route.params.id;
    }
  },
  computed: {
    alreadyApproved() {
      return this.isGM && this.so.id_gm
    }
  },
  methods: {
    async submit() {
      // this.items = this.items.map(item => ({
      //   // id: item.id,
      //   stok_opname_id: this.$route.params.id,
      //   penyimpanan_id: item.id,
      //   stok_in: item.jumlah,
      //   stok_real: item.stok_real,
      //   // penyesuaian: item.penyesuaian
      // }))
      let payloads = []
      this.items.map(item => {
        payloads.push({
        stok_opname_id: this.$route.params.id,
        penyimpanan_id: item.id,
        stok_in: item.jumlah,
        stok_real: item.stok_real,
        })
      })

      try {
        this.loading = true
        await this.$store.dispatch('tokostokopname/saveSo', payloads )
        await this.displaySuccess({
          message: 'Stok Opname berhasil disimpan'
        })
        await this.getDataSo()
        this.loading = false
      }
      catch(e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    updatePenyesuaian(index) {
      this.items[index].penyesuaian = this.items[index].jumlah - this.items[index].stok_real
    },
    setField() {
      if(this.alreadyApproved) {
        this.fields = [
          {
            key: 'checkbox',
            label: '#'
          },
          {
            key: "no",
            label: "No",
          },
          {
            key: "detail.nama",
            label: "Nama Barang",
            sortable: true
          },
          {
            key: "detail.blok",
            label: "Blok",
            sortable: true
          },
          {
            key: "detail.palet",
            label: "palet",
          },
          {
            key: "stok",
            label: "Stok",
          },
          {
            key: "detail.satuan",
            label: "satuan",
          },
          {
            key: "stok_real",
            label: "Hasil Stok Opname",
          },
          {
            key: "selisih",
            label: "selisih barang",
          },
          {
            key: 'penyesuaian',
            label: 'Penyesuaian'
          }
        ]
      }
      if(this.isCabang){
        this.fields = this.fieldsCabang
      }
    },
    approve() {
      this.$swal({
        title: 'Peringatan',
        text: 'Dengan menyetujui stock opname ini, anda dapat melakukan penyesuaian hasilnya',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false
      })
      .then(async res => {
        if(res.value) {
          const payload = {
            id: this.$route.params.id,
            id_gudang: this.so.id_gudang,
            tanggal: this.so.tanggal,
            id_karyawan: this.so.id_karyawan,
            id_gm: this.user.karyawan ? this.user.karyawan.id : null
          }

          try {
            this.loading = true

            await this.$store.dispatch('tokostokopname/save', [payload])
            await this.getDataSo()
            this.displaySuccess({
              message: 'Stok opname sudah disetujui!'
            })

            this.loading = false
          }
          catch(e) {
            this.loading = false
            this.displayError(e)
            return false
          }
        }
      })
    },
    resetform() {
      this.item.stok_real = 0;
      this.stok_real = 0;
    },
    async save() {
      if (this.id) {
        this.id = this.id
      }
      let payloads = []
      this.items.map(item => {
        payloads.push({
          stok_opname_id: this.$route.params.id,
          penyimpanan_id: item.id,
          stok_in: item.jumlah,
          stok_real: item.stok_real
        })
      })
      let payload1 = {
        status: this.status
      }
      if (this.$route.params.id) {
            payload1.id = this.$route.params.id;
          }
      try {
        this.loading = true
        await this.$store.dispatch('tokostokopname/saveSo', payloads)
        // await this.$store.dispatch('tokostokopname/save', [payload1])
        this.loading = false

        this.displaySuccess({
          text: 'Stok Opname berhasil disimpan!'
        })

        this.resetForm();
        // this.getRincian();

      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    async getData() {
      // const perPage = parseInt(this.perPage);
      // const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        // start: currentPage,
        // length: this.perPage,
        gudang_id: this.myGudang.id,
      };

      const stocks = await this.$store.dispatch("tokopenyimpanan/getData", payload)
      let items = JSON.parse(JSON.stringify(this.$store.state.tokopenyimpanan.datas));
      let items_total = this.$store.state.tokopenyimpanan.totals;
      this.items = items;
      // this.items = items;
      this.totalRows = items_total;
      // this.totalRows = JSON.parse( JSON.stringify(stocks) ).length
    },
    async getDataSo() {
      const so = await this.$store
        .dispatch("tokostokopname/getDataById", this.$route.params.id);
          this.so = so;
    }
  },
  watch: {
    selectedBarang(barang) {
      if (barang) {
        this.barangs.push({
          id: barang.value,
          name: barang.text,
          stok_real: 0,
          isFinalStock: false,
        });
      }
    },
  },
};
</script>
